import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ConfigProvider, theme, App as AApp } from "antd";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import PageContainer from "./PageContainer";
import Random from "./pages/Random";
import About from "./pages/About";
import NotFound from "./pages/NotFound";
import Network from "./pages/Network";
import Contact from "./pages/Contact";
import { MonoFontFamily } from "./libraries/Endeavour";
import Data from "./pages/Data";
import Crypto from "./pages/Crypto";
import Login from "./pages/Login";
import Base64 from "./pages/Base64";
import QRCode from "./pages/QRCode";
import Hash from "./pages/Hash";
import Telegram from "./pages/Telegram";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 0,
          fontFamily: "Kanit, 'Noto Sans TC'",
          colorPrimary: "#15395b",
        },
        components: {
          Descriptions: {
            fontFamily: MonoFontFamily,
          },
        },
        // algorithm: [theme.darkAlgorithm],
        algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
        // algorithm: [theme.compactAlgorithm],
      }}
    >
      <QueryClientProvider client={queryClient}>
        <AApp>
          <BrowserRouter basename="/">
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route element={<PageContainer />}>
                <Route path="/" element={<Home />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/data" element={<Data />} />
                <Route path="/network" element={<Network />} />
                <Route path="/crypto" element={<Crypto />} />
                <Route path="tools">
                  <Route path="random" element={<Random />} />
                  <Route path="qrcode" element={<QRCode />} />
                  <Route path="base64" element={<Base64 />} />
                  <Route path="hash" element={<Hash />} />
                  <Route path="telegram" element={<Telegram />} />
                </Route>
                <Route path="/contact-us" element={<Contact />} />
                <Route path="*" element={<NotFound />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </AApp>
      </QueryClientProvider>
    </ConfigProvider>
  );
}

export default App;
