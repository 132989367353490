import { Card, Form, Input, Typography } from "antd";
import { Col, Row } from "../libraries/Endeavour";
import { useEffect, useState } from "react";

function toHex(buffer) {
  return Array.from(new Uint8Array(buffer))
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
}

function Hash() {
  const [input, setInput] = useState("");
  const [output, setOutput] = useState({
    md5: "",
    sha1: "",
    sha256: "",
    sha512: "",
  });
  useEffect(() => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);

    crypto.subtle
      .digest("SHA-1", data)
      .then((t) => setOutput((v) => ({ ...v, sha1: toHex(t) })));
    crypto.subtle
      .digest("SHA-256", data)
      .then((t) => setOutput((v) => ({ ...v, sha256: toHex(t) })));
    crypto.subtle
      .digest("SHA-512", data)
      .then((t) => setOutput((v) => ({ ...v, sha512: toHex(t) })));
  }, [input]);
  return (
    <Row>
      <Col>
        <Card title="Hash Generator (SHA1, SHA256, SHA512)">
          <Form layout="vertical">
            <Form.Item label="Input">
              <Input autoFocus onChange={(e) => setInput(e.target.value)} />
            </Form.Item>
          </Form>
          <Typography.Paragraph>
            <Typography.Title level={3}>SHA-1 (Length: 40)</Typography.Title>
            <Typography.Text copyable>{output.sha1}</Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph level={3}>
            <Typography.Title>SHA-256 (Length: 64)</Typography.Title>
            <Typography.Text copyable>{output.sha256}</Typography.Text>
          </Typography.Paragraph>
          <Typography.Paragraph level={3}>
            <Typography.Title>SHA-512 (Length: 128)</Typography.Title>
            <Typography.Text copyable>{output.sha512}</Typography.Text>
          </Typography.Paragraph>
        </Card>
      </Col>
    </Row>
  );
}

export default Hash;
