import { useEffect, useRef, memo } from "react";

function TradingViewTicker() {
  const container = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.type = "text/javascript";
    script.async = true;
    script.innerHTML = `
    {
        "symbols": [
          {
            "description": "ETHUSDT",
            "proName": "BINANCE:ETHUSDT"
          },
          {
            "description": "BTCUSDT",
            "proName": "BINANCE:BTCUSDT"
          },
          {
            "description": "HSI",
            "proName": "HSI:HSI"
          },
          {
            "description": "",
            "proName": "NASDAQ:AAPL"
          },
          {
            "description": "",
            "proName": "NASDAQ:NVDA"
          }
        ],
        "showSymbolLogo": true,
        "isTransparent": true,
        "displayMode": "compact",
        "colorTheme": "dark",
        "locale": "en"
      }
        `;
    container.current.appendChild(script);
  }, []);

  return (
    <div
      style={{
        height: 72,
        overflowY: "hidden",
      }}
    >
      <div className="tradingview-widget-container" ref={container}>
        <div className="tradingview-widget-container__widget"></div>
        <div className="tradingview-widget-copyright">
          <a
            href="https://www.tradingview.com/"
            rel="noopener nofollow"
            target="_blank"
          ></a>
        </div>
      </div>
    </div>
  );
}

export default memo(TradingViewTicker);
