import { LoginOutlined } from "@ant-design/icons";
import { Button, Card, Form, Input, Space } from "antd";
import { useEffect, useRef, useState } from "react";
import { Col, Row } from "../libraries/Endeavour";

function Home() {
  const [isLoading] = useState(false);

  return (
    <Row>
      <Col span={12}>
        <Card title="Login">
          <Form layout="vertical" size="large">
            <Form.Item label="Username" name="username" required>
              <Input autoFocus />
            </Form.Item>
            <Form.Item label="Password" name="password" required>
              <Input.Password visibilityToggle />
            </Form.Item>
            <Form.Item label="One Time Password" required>
              <Input.OTP size="large" length={6} />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button
                  htmlType="submit"
                  type="primary"
                  icon={<LoginOutlined />}
                  loading={isLoading}
                >
                  Login
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
}

export default Home;
