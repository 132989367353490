import { Col as ACol, Row as ARow, Typography } from "antd";

const time = () => {
  return Math.floor(Date.now() / 1000);
};

const Row = ({ gutter = [16, 16], children, ...props }) => {
  return (
    <ARow gutter={gutter} {...props}>
      {children}
    </ARow>
  );
};

const Col = ({ span = 24, children, ...props }) => {
  const breakpoint = {
    span: span,
    xs: 24,
    sm: 24,
    md: span,
    lg: span,
  };
  return (
    <ACol span={span} {...breakpoint} {...props}>
      {children}
    </ACol>
  );
};

const ulid = () => {
  const BASE32 = "0123456789ABCDEFGHJKMNPQRSTVWXYZ";
  const BASE32_LENGTH = BASE32.length;
  const TIME_LENGTH = 10;
  const RANDOM_LENGTH = 16;

  const encodeTime = (now, length) => {
    let encoded = "",
      mod;
    for (; length > 0; length--) {
      mod = now % BASE32_LENGTH;
      encoded = BASE32.charAt(mod) + encoded;
      now = (now - mod) / BASE32_LENGTH;
    }
    return encoded;
  };

  const randomChar = () => {
    const buffer = new Uint8Array(1);
    crypto.getRandomValues(buffer);
    let random = Math.floor((buffer[0] / 0xff) * BASE32_LENGTH);
    if (random === BASE32_LENGTH) {
      random = random - 1;
    }
    return BASE32.charAt(random);
  };

  const encodeRandom = (length) => {
    let encoded = "";
    for (; length > 0; length--) {
      encoded = randomChar() + encoded;
    }
    return encoded;
  };

  const now = Date.now();
  const timePart = encodeTime(now, TIME_LENGTH);
  const randomPart = encodeRandom(RANDOM_LENGTH);

  return timePart + randomPart;
};

const InlineCopyable = ({ children, ...props }) => {
  return (
    <Typography.Text copyable {...props}>
      {children}
    </Typography.Text>
  );
};

const ISO8601 = (time, textOnly = false) => {
  if (time === null || time === undefined) {
    return;
  }
  if (/\d{10}/.test(time)) {
    time = time * 1000;
  }

  const date = new Date(time);
  const formatted = date.toLocaleString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  });

  const [datePart, timePart] = formatted.split(", ");
  const [month, day, year] = datePart.split("/");

  return `${year}-${month}-${day} ${timePart}`;
};

const getChainTypeByHash = (input) => {
  const patterns = [
    {
      blockchain: "BTC",
      type: "address",
      regex: /^1[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    {
      blockchain: "BTC",
      type: "address",
      regex: /^3[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    {
      blockchain: "BTC",
      type: "address",
      regex: /^bc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{39,59}$/,
    },
    { blockchain: "BTC", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    { blockchain: "ETH", type: "address", regex: /^0x[a-fA-F0-9]{40}$/ },
    { blockchain: "ETH", type: "transaction", regex: /^0x[a-fA-F0-9]{64}$/ },
    {
      blockchain: "LTC",
      type: "address",
      regex: /^L[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    {
      blockchain: "LTC",
      type: "address",
      regex: /^M[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    {
      blockchain: "LTC",
      type: "address",
      regex: /^ltc1[qpzry9x8gf2tvdw0s3jn54khce6mua7l]{39,59}$/,
    },
    { blockchain: "LTC", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    {
      blockchain: "XRP",
      type: "address",
      regex: /^r[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    { blockchain: "XRP", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    {
      blockchain: "BCH",
      type: "address",
      regex: /^q[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    {
      blockchain: "BCH",
      type: "address",
      regex: /^p[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    { blockchain: "BCH", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    {
      blockchain: "DOGE",
      type: "address",
      regex: /^D[1-9A-HJ-NP-Za-km-z]{25,34}$/,
    },
    { blockchain: "DOGE", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    {
      blockchain: "TRX",
      type: "address",
      regex: /^T[1-9A-HJ-NP-Za-km-z]{33}$/,
    },
    { blockchain: "TRX", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    {
      blockchain: "SOL",
      type: "address",
      regex: /^[1-9A-HJ-NP-Za-km-z]{32,44}$/,
    },
    { blockchain: "SOL", type: "transaction", regex: /^[a-fA-F0-9]{88}$/ },
    { blockchain: "Unknown", type: "transaction", regex: /^[a-fA-F0-9]{64}$/ },
    { blockchain: "Unknown", type: "hash", regex: /^[a-fA-F0-9]{32}$/ },
  ];

  for (const pattern of patterns) {
    if (pattern.regex.test(input)) {
      return { blockchain: pattern.blockchain, type: pattern.type };
    }
  }

  return { blockchain: "Unknown", type: "Unknown" };
};

const MonoFontFamily = "'Source Code Pro'";

export {
  InlineCopyable,
  MonoFontFamily,
  time,
  Row,
  Col,
  ISO8601,
  getChainTypeByHash,
  ulid,
};
