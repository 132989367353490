import { useQuery } from "@tanstack/react-query";
import { Card, Descriptions, Skeleton } from "antd";
import { InlineCopyable, MonoFontFamily } from "../libraries/Endeavour";

const IPinfo = () => {
  const query = useQuery({
    queryKey: ["ipinfo"],
    queryFn: () => {
      return fetch("https://ipinfo.io/json", {
        headers: {
          Accept: "application/json",
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          return data;
        });
    },
  });

  return (
    <Card title="IPinfo - Your IP Information">
      {query.isFetching ? (
        <Skeleton active />
      ) : (
        <Descriptions column={1}>
          <Descriptions.Item label="IP">
            <InlineCopyable
              style={{
                fontFamily: MonoFontFamily,
              }}
            >
              {query.data.ip}
            </InlineCopyable>
          </Descriptions.Item>
          <Descriptions.Item label="City">{query.data.city}</Descriptions.Item>
          <Descriptions.Item label="Region">
            {query.data.region}
          </Descriptions.Item>
          <Descriptions.Item label="Country">
            {query.data.country}
          </Descriptions.Item>
          <Descriptions.Item label="Location">
            {query.data.loc}
          </Descriptions.Item>
          <Descriptions.Item label="Organization">
            {query.data.org}
          </Descriptions.Item>
          <Descriptions.Item label="Timezone">
            <InlineCopyable
              style={{
                fontFamily: MonoFontFamily,
              }}
            >
              {query.data.timezone}
            </InlineCopyable>
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
};

export default IPinfo;
