import { App, Col, Row, Typography } from "antd";
import React from "react";

function About() {
  const { notification } = App.useApp();

  return (
    <Row>
      <Col span={24}>
        <Typography>
          <Typography.Title>About Us</Typography.Title>
          <Typography.Paragraph>
            Welcome to my page! I am a seasoned programmer with over 20 years of
            experience in the technology industry. For the past decade, I have
            been working with a leading payment company, where I have honed my
            skills as a full-stack developer and problem solver.
          </Typography.Paragraph>
          <Typography.Title level={3}>My Journey</Typography.Title>
          <Typography.Paragraph>
            My journey in technology began over two decades ago, sparked by a
            fascination with how things work and a desire to build solutions
            that make a difference. Over the years, I have developed a deep
            expertise in various programming languages and technologies,
            including PHP, Node.js, Java, React, Python, and more.
          </Typography.Paragraph>
          <Typography.Title level={3}>Professional Experience</Typography.Title>
          <Typography.Paragraph>
            I have been instrumental in developing and maintaining critical
            payment systems that ensure secure and seamless transactions for
            millions of users. My role involves everything from designing robust
            backend architectures to crafting intuitive frontend interfaces. I
            thrive on challenges and take pride in delivering high-quality
            solutions that meet both business and user needs.
          </Typography.Paragraph>
          <Typography.Title level={3}>Notable Projects</Typography.Title>
          <Typography.Paragraph>
            <ul>
              <li>
                Payment Gateway: Designed and implemented a scalable payment
                gateway that processes millions of transactions daily with high
                reliability and security.
              </li>
              <li>
                ERP System: Developed a comprehensive ERP solution that
                streamlined business operations and improved efficiency for
                several large organizations.
              </li>
              <li>
                Content Management Systems (CMS): Created and customized
                multiple CMS platforms that empowered clients to manage their
                content effortlessly.
              </li>
            </ul>
          </Typography.Paragraph>
          <Typography.Title level={3}>Philosophy and Approach</Typography.Title>
          <Typography.Paragraph>
            I believe that every problem has a solution, and it's just a matter
            of finding the right approach. My philosophy revolves around
            understanding the problem, defining clear goals, persistent effort,
            and unwavering commitment. Whether it's debugging a complex issue or
            optimizing a system for better performance, I am committed to
            achieving the best possible outcomes.
          </Typography.Paragraph>
          <Typography.Title level={3}>Career Goals</Typography.Title>
          <Typography.Paragraph>
            As I look to the future, my goal is to continually improve our work
            processes and embrace automation to enhance productivity. I aspire
            to be "lazy enough" to automate repetitive tasks, thereby freeing up
            time for more innovative and impactful work. This approach not only
            makes our work better over time but also embodies the essence of
            great programming.
          </Typography.Paragraph>
          <Typography.Title level={3}>Beyond Programming</Typography.Title>
          <Typography.Paragraph>
            When I'm not immersed in code, my passion for work continues to
            drive me. I am constantly exploring new technologies and
            methodologies to stay ahead in the ever-evolving tech landscape.
            Thank you for visiting my portfolio. Feel free to explore my work
            and reach out if you have any questions or opportunities to
            collaborate.
          </Typography.Paragraph>
        </Typography>
      </Col>
    </Row>
  );
}

export default About;
