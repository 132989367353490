import { Card, Form, Input, Typography } from "antd";
import ImageBase64 from "../components/ImageBase64";
import { Col, Row } from "../libraries/Endeavour";
import { useState } from "react";

function Base64() {
  const [encoded, setEncoded] = useState("");
  const [decoded, setDecoded] = useState("");
  return (
    <Row>
      <Col span={12}>
        <ImageBase64 />
      </Col>
      <Col>
        <Row>
          <Col span={12}>
            <Card
              title="Base64 Encode"
              extra={
                <span style={{ fontStyle: "italic" }}>
                  Your data stays on client-side.
                </span>
              }
            >
              <Form layout="vertical">
                <Form.Item label="Text">
                  <Input.TextArea
                    onChange={(e) => {
                      setEncoded(btoa(e.target.value));
                    }}
                  />
                </Form.Item>
                <Typography.Text copyable>{encoded}</Typography.Text>
              </Form>
            </Card>
          </Col>
          <Col span={12}>
            <Card
              title="Base64 Decode"
              extra={
                <span style={{ fontStyle: "italic" }}>
                  Your data stays on client-side.
                </span>
              }
            >
              <Form layout="vertical">
                <Form.Item label="Text">
                  <Input.TextArea
                    onChange={(e) => {
                      setDecoded(atob(e.target.value));
                    }}
                  />
                </Form.Item>
                <Typography.Text copyable>{decoded}</Typography.Text>
              </Form>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Base64;
