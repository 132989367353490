import { LoginOutlined, QuestionOutlined } from "@ant-design/icons";
import { Button, Card, Divider, Form, Input, Layout, Space } from "antd";
import { Col, Row } from "../libraries/Endeavour";

function Login() {
  return (
    <Layout>
      <Layout.Content
        style={{
          padding: 16,
          minHeight: "100vh",
        }}
      >
        <Row
          style={{
            display: "flex",
            justifyContent: "center",
            justifyItems: "center",
          }}
        >
          <Col span={12}>
            <Card title="Restricted Area" size="large">
              <Form layout="vertical" size="large">
                <Form.Item label="Username" required>
                  <Input name="username" autoFocus />
                </Form.Item>
                <Form.Item label="Password" required>
                  <Input.Password name="password" />
                </Form.Item>
                <Form.Item
                  label="MFA Password"
                  tooltip="Google Authenticator 6 digitl passcode"
                >
                  <Input.OTP length={6} name="otp" />
                </Form.Item>
                <Form.Item>
                  <Space>
                    <Button
                      htmlType="submit"
                      icon={<LoginOutlined />}
                      type="primary"
                    >
                      Login
                    </Button>
                    <Button icon={<QuestionOutlined />} type="default">
                      Forget Password
                    </Button>
                  </Space>
                </Form.Item>
              </Form>
            </Card>
          </Col>
          <Col
            span={24}
            style={{
              textAlign: "center",
            }}
          >
            Powered by YIPSINT
          </Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}

export default Login;
