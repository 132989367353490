import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";

import { Card, Form, Input, QRCode, Button, Image } from "antd";

function ImageBase64() {
  const [value, setValue] = useState("");
  return (
    <Card
      title="Base64 Image Renderer"
      extra={
        <span style={{ fontStyle: "italic" }}>
          Your data stays on client-side.
        </span>
      }
    >
      <Form
        layout="vertical"
        onValuesChange={(changed, values) => setValue(values.value.trim())}
      >
        <Form.Item label="Image Base64 String" name="value">
          <Input.TextArea rows={2} />
        </Form.Item>
      </Form>
      <Image src={value} preview={false} />
    </Card>
  );
}

export default ImageBase64;
