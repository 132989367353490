import { BackwardOutlined, RollbackOutlined } from "@ant-design/icons";
import { Button, Result } from "antd";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <Result
      status={404}
      title="404 Not Found"
      subTitle="Sorry, the page you trying to visit does not exist."
      extra={
        <Button
          icon={<RollbackOutlined />}
          onClick={() => {
            navigate("/");
          }}
        >
          Back Home
        </Button>
      }
    />
  );
}

export default NotFound;
