import { DownloadOutlined } from "@ant-design/icons";
import { useState } from "react";

import { Card, Form, Input, QRCode, Button } from "antd";

function QRCodeGenerator() {
  const [qrcodeValue, setQrcodeValue] = useState("");
  return (
    <Card
      title="QRCode Generator"
      extra={
        <span style={{ fontStyle: "italic" }}>
          Your data stays on client-side.
        </span>
      }
    >
      <Form
        layout="vertical"
        onValuesChange={(changed, values) =>
          setQrcodeValue(values.value.trim())
        }
      >
        <Form.Item label="QRCode Value" name="value">
          <Input.TextArea rows={2} />
        </Form.Item>
        {qrcodeValue.length > 0 && (
          <Form.Item>
            <Button
              icon={<DownloadOutlined />}
              onClick={() => {
                const canvas = document
                  .getElementById("qrcode-generator")
                  .querySelector("canvas");
                if (!canvas) {
                  return;
                }
                const url = canvas.toDataURL();
                const a = document.createElement("a");
                a.download = "QRCode.png";
                a.href = url;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
              }}
            >
              Download QRCode
            </Button>
          </Form.Item>
        )}
      </Form>
      {qrcodeValue.length > 0 && (
        <div id="qrcode-generator">
          <QRCode
            value={qrcodeValue}
            size={300}
            color="#000000"
            bgColor="#FFFFFF"
          />
        </div>
      )}
    </Card>
  );
}

export default QRCodeGenerator;
