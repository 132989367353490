import { DatabaseOutlined, EditOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  Input,
  InputNumber,
  QRCode,
  Row,
  Switch,
  Table,
} from "antd";
import { useState } from "react";
import { InlineCopyable, MonoFontFamily, ulid } from "../libraries/Endeavour";
import QRCodeGenerator from "../components/QRCodeGenerator";
import ImageBase64 from "../components/ImageBase64";

function generateRandomString(options) {
  const { length, lowercase, uppercase, number, special } = options;

  const lowercaseChars = "abcdefghijklmnopqrstuvwxyz";
  const uppercaseChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numberChars = "0123456789";
  const specialChars = "!@#$%^&*()_+~`|}{[]:;?><,./-=";

  let allChars = "";
  if (lowercase) allChars += lowercaseChars;
  if (uppercase) allChars += uppercaseChars;
  if (number) allChars += numberChars;
  if (special) allChars += specialChars;

  if (allChars.length === 0) {
    throw new Error("No characters available to generate the string.");
  }

  const randomChar = () => {
    const buffer = new Uint8Array(1);
    crypto.getRandomValues(buffer);
    let random = Math.floor((buffer[0] / 0xff) * allChars.length);
    if (random === allChars.length) {
      random = random - 1;
    }
    return allChars.charAt(random);
  };

  let result = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * allChars.length);
    result += randomChar();
  }

  return result;
}

function Random() {
  const [uuids, setUuids] = useState([]);
  const [ulids, setUlids] = useState([]);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Row gutter={[16, 16]}>
      <Col span={12} xs={24} sm={24} md={12} lg={12}>
        <Card
          title="Random String"
          extra={
            <span style={{ fontStyle: "italic" }}>
              Your data stays on client-side.
            </span>
          }
        >
          <Form
            layout="vertical"
            onFinish={(data) => {
              setIsLoading(true);
              let values = [];
              for (let i = 1; i <= data.results; i++) {
                values.push({
                  id: i,
                  value: generateRandomString(data),
                });
              }
              setResults(values);
              setIsLoading(false);
            }}
            initialValues={{
              uppercase: 1,
              lowercase: 1,
              number: 1,
              special: 1,
              length: 12,
              results: 10,
            }}
          >
            <Row>
              <Col span={8}>
                <Form.Item
                  label="Uppercase"
                  tooltip="ABCDEFGHIJKLMNOPQRSTUVWXYZ"
                  name="uppercase"
                >
                  <Switch value={1} />
                </Form.Item>
                <Form.Item
                  label="Lowercase"
                  tooltip="abcdefghijklmnopqrstuvwxyz"
                  name="lowercase"
                >
                  <Switch value={1} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item label="Numbers" tooltip="1234567890" name="number">
                  <Switch value={1} />
                </Form.Item>
                <Form.Item
                  label="Special Characters"
                  tooltip="!@#$%^&*()"
                  name="special"
                >
                  <Switch value={1} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Length"
                  name="length"
                  tooltip="For PCI compliance, use a password length of at least 12 characters"
                >
                  <InputNumber value={12} />
                </Form.Item>
                <Form.Item label="# of Results" name="results">
                  <InputNumber value={10} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<DatabaseOutlined />}
                    loading={isLoading}
                  >
                    Generate
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
          <Table
            size="small"
            bordered
            rowKey={"id"}
            columns={[
              { title: "ID", align: "center", dataIndex: "id" },
              {
                title: "String",
                align: "center",
                render: (data) => {
                  return (
                    <InlineCopyable
                      style={{
                        fontFamily: MonoFontFamily,
                      }}
                    >
                      {data.value}
                    </InlineCopyable>
                  );
                },
              },
            ]}
            dataSource={results}
          />
        </Card>
      </Col>
      <Col span={12} xs={24} sm={24} md={12} lg={12}>
        <Card
          title="Random UUID"
          extra={
            <span style={{ fontStyle: "italic" }}>
              Your data stays on client-side.
            </span>
          }
        >
          <Form>
            <Form.Item>
              <Button
                type="primary"
                loading={isLoading}
                icon={<DatabaseOutlined />}
                onClick={() => {
                  setIsLoading(true);
                  let data = [];
                  for (let i = 1; i <= 10; i++) {
                    data.push({ id: i, value: crypto.randomUUID() });
                  }
                  setUuids(data);
                  setIsLoading(false);
                }}
              >
                Generate
              </Button>
            </Form.Item>
          </Form>
          <Table
            size="small"
            bordered
            rowKey={"id"}
            pagination={false}
            columns={[
              { title: "ID", align: "center", dataIndex: "id" },
              {
                title: "String",
                align: "center",
                render: (data) => {
                  return (
                    <InlineCopyable
                      style={{
                        fontFamily: MonoFontFamily,
                      }}
                    >
                      {data.value}
                    </InlineCopyable>
                  );
                },
              },
            ]}
            dataSource={uuids}
          />
        </Card>
      </Col>
      <Col span={12} xs={24} sm={24} md={12} lg={12}>
        <Card
          title="Random ULID"
          extra={
            <span style={{ fontStyle: "italic" }}>
              Your data stays on client-side.
            </span>
          }
        >
          <Form>
            <Form.Item>
              <Button
                type="primary"
                loading={isLoading}
                icon={<DatabaseOutlined />}
                onClick={() => {
                  setIsLoading(true);
                  let data = [];
                  for (let i = 1; i <= 10; i++) {
                    data.push({ id: i, value: ulid() });
                  }
                  setUlids(data);
                  setIsLoading(false);
                }}
              >
                Generate
              </Button>
            </Form.Item>
          </Form>
          <Table
            size="small"
            bordered
            rowKey={"id"}
            pagination={false}
            columns={[
              { title: "ID", align: "center", dataIndex: "id" },
              {
                title: "String",
                align: "center",
                render: (data) => {
                  return (
                    <InlineCopyable
                      style={{
                        fontFamily: MonoFontFamily,
                      }}
                    >
                      {data.value}
                    </InlineCopyable>
                  );
                },
              },
            ]}
            dataSource={ulids}
          />
        </Card>
      </Col>
    </Row>
  );
}

export default Random;
