import { Alert, Button, Card, Descriptions, Skeleton } from "antd";
import { useState } from "react";
import IPinfo from "../components/IPinfo";
import { Col, Row } from "../libraries/Endeavour";

function getCardinalHeadingDirection(heading) {
  if (heading === null || heading === undefined || isNaN(heading)) {
    return "Unknown";
  }

  const directions = ["N", "NE", "E", "SE", "S", "SW", "W", "NW"];
  const index = Math.round(heading / 45) % 8;
  return directions[index];
}

const geolocationOptions = {
  maximumAge: 0,
  timeout: 5000,
  enableHighAccuracy: true,
};

function Network() {
  const [geolocation, setGeolocation] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);

  const getGeolocation = () => {
    if (!navigator.geolocation) {
      return;
    }
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setGeolocation(position.coords);
      },
      () => {},
      geolocationOptions
    );

    navigator.geolocation.watchPosition(
      (position) => {
        setGeolocation(position.coords);
        const { latitude, longitude } = position.coords;
        setCurrentPosition({
          lat: latitude,
          lng: longitude,
        });
      },
      (err) => {
        alert(err.message);
      },
      geolocationOptions
    );
  };

  return (
    <Row>
      <Col span={12}>
        <IPinfo />
      </Col>
      <Col span={12}>
        <Card
          title="Geolocation"
          extra={
            <Button type="primary" onClick={getGeolocation}>
              Get Geolocation
            </Button>
          }
        >
          {geolocation ? (
            <Descriptions column={1}>
              <Descriptions.Item label="Longitude">
                {geolocation?.longitude}
              </Descriptions.Item>
              <Descriptions.Item label="Latitude">
                {geolocation?.latitude}
              </Descriptions.Item>
              <Descriptions.Item label="Accuracy">
                {geolocation?.accuracy}
              </Descriptions.Item>
              <Descriptions.Item label="Altitude">
                {geolocation?.altitude}
              </Descriptions.Item>
              <Descriptions.Item label="Altitude Accuracy">
                {geolocation?.altitudeAccuracy}
              </Descriptions.Item>
              <Descriptions.Item label="Speed">
                {geolocation?.speed
                  ? `${(geolocation.speed * 3.6).toFixed(2)} km/h`
                  : `0.00 km/h`}
              </Descriptions.Item>
              <Descriptions.Item label="Heading">
                {geolocation?.heading &&
                  getCardinalHeadingDirection(geolocation.heading)}
              </Descriptions.Item>
            </Descriptions>
          ) : (
            <>
              <Alert
                message="tap the Get Geolocation to start viewing your position. (Your position only available to your own device.)"
                showIcon
              />
              <Skeleton active />
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Network;
