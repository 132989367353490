import { Button, Card, Form, Input, Typography } from "antd";
import { Col, Row } from "../libraries/Endeavour";
import { useState } from "react";
import { DownloadOutlined, SaveOutlined } from "@ant-design/icons";

function Telegram() {
  const [getWebhookForm] = Form.useForm();
  const [setWebhookForm] = Form.useForm();
  const [info, setInfo] = useState("");
  const [updateInfo, setUpdateInfo] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Row>
      <Col span={12}>
        <Card
          title="Get Webhook Info"
          extra={
            <span style={{ fontStyle: "italic" }}>
              Your data stays on client-side.
            </span>
          }
        >
          <Form layout="vertical" form={getWebhookForm}>
            <Form.Item
              label="API Token"
              name="api_token"
              required
              rules={[{ required: true }, { pattern: /^\d+:[A-Za-z0-9-]+$/ }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                icon={<DownloadOutlined />}
                loading={isLoading}
                type="primary"
                onClick={() => {
                  getWebhookForm
                    .validateFields()
                    .then((values) => {
                      setIsLoading(true);
                      fetch(
                        `https://api.telegram.org/bot${values.api_token}/getWebhookInfo`
                      )
                        .then((res) => res.json())
                        .then((data) => {
                          setInfo(JSON.stringify(data, null, 4));
                        })
                        .finally(() => {
                          setIsLoading(false);
                        });
                    })
                    .catch((errors) => {});
                }}
              >
                Fetch
              </Button>
            </Form.Item>
          </Form>
          {info.length > 0 && <Typography.Text code>{info}</Typography.Text>}
        </Card>
      </Col>
      <Col span={12}>
        <Card
          title="Set Webhook URL"
          extra={
            <span style={{ fontStyle: "italic" }}>
              Your data stays on client-side.
            </span>
          }
        >
          <Form form={setWebhookForm} layout="vertical">
            <Form.Item
              label="API Token"
              name="api_token"
              required
              rules={[{ required: true }, { pattern: /^\d+:[A-Za-z0-9-]+$/ }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="URL"
              name="url"
              required
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                loading={isLoading}
                icon={<SaveOutlined />}
                onClick={() => {
                  setWebhookForm
                    .validateFields()
                    .then((values) => {
                      setIsLoading(true);
                      fetch(
                        `https://api.telegram.org/bot${values.api_token}/setWebhook?url=${values.url}`,
                        {
                          method: "POST",
                        }
                      )
                        .then((res) => res.json())
                        .then((data) =>
                          setUpdateInfo(JSON.stringify(data, null, 4))
                        )
                        .finally(() => {
                          setIsLoading(false);
                        });
                    })
                    .catch((errors) => {
                      console.log(errors);
                    });
                }}
              >
                Save
              </Button>
            </Form.Item>
          </Form>
          {updateInfo.length > 0 && (
            <Typography.Text code>{updateInfo}</Typography.Text>
          )}
        </Card>
      </Col>
    </Row>
  );
}

export default Telegram;
