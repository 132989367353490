import { useQuery } from "@tanstack/react-query";
import { Card, Table } from "antd";
import HKWeatherCard from "../components/HKWeatherCard";
import { Col, ISO8601, Row } from "../libraries/Endeavour";
import HKAirportBoardCard from "../components/HKAirportBoardCard";

function Data() {
  const holiday = useQuery({
    queryKey: ["holiday"],
    queryFn: () => {
      return fetch("/data/holiday/hk")
        .then((res) => res.json())
        .then((data) => {
          return data.payload;
        });
    },
  });
  const hospital = useQuery({
    queryKey: ["hospital"],
    queryFn: () => {
      return fetch("/data/hospital/wait-time")
        .then((res) => res.json())
        .then((res) => {
          return res.payload;
        });
    },
  });

  return (
    <Row>
      <Col span={12}>
        <HKWeatherCard />
      </Col>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Card title="Hong Kong Public Hospital Wait Time">
              <Table
                rowKey={"name"}
                size="small"
                dataSource={hospital.data}
                columns={[
                  { title: "Hospital", dataIndex: "hospName" },
                  {
                    title: "Wait Time",
                    align: "center",
                    dataIndex: "topWait",
                  },
                ]}
              />
            </Card>
          </Col>
          <Col span={12}>
            <Card title="Hong Kong Public Holiday">
              <Table
                size="small"
                dataSource={holiday.data}
                columns={[
                  { title: "Name of Holiday", dataIndex: "name" },
                  {
                    title: "Start Date",
                    align: "center",
                    render: (data) => {
                      return ISO8601(data.start);
                    },
                  },
                  {
                    title: "End Date",
                    align: "center",
                    render: (data) => {
                      return ISO8601(data.end);
                    },
                  },
                  {
                    title: "Duration (D)",
                    align: "center",
                    render: (data) => {
                      return Math.floor((data.end - data.start) / 86400);
                    },
                  },
                ]}
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Data;
