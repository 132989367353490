import { EditOutlined } from "@ant-design/icons";
import {
  Card,
  Row,
  Col,
  Form,
  Input,
  Space,
  Select,
  Button,
  Segmented,
  QRCode,
  Image,
  Typography,
  Statistic,
  Result,
  Flex,
} from "antd";
import { useEffect, useState } from "react";

let interval;

// const hostname = "http://localhost:8080";
const hostname = "";

function Contact() {
  const [payment, setPayment] = useState({});
  const [received, setReceived] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(payment).length >= 2) {
      interval = setInterval(() => {
        fetch(`${hostname}/demo/payments/${payment.uuid}`)
          .then((res) => res.json())
          .then((res) => {
            if (res.payload.status !== "success") {
              return;
            }
            clearInterval(interval);
            setReceived(res.payload);
          });
      }, 5000);
    }
  }, [payment]);

  return (
    <Row gutter={[16, 16]}>
      <Col span={12} xs={24} sm={24} md={12} lg={12}>
        <Card title="Demo Payment">
          <Form
            onFinish={(values) => {
              setLoading(true);
              fetch(`${hostname}/demo/${values.currency}/payments`, {
                method: "POST",
                body: JSON.stringify(values),
              })
                .then((res) => res.json())
                .then((res) => {
                  console.log(res);
                  setPayment(res.payload);
                })
                .finally(() => {
                  setLoading(false);
                });
            }}
            layout="vertical"
            initialValues={{
              amount: 100,
              currency: "cny",
            }}
          >
            <Form.Item label="Currency" name={"currency"}>
              <Select
                options={[
                  { value: "cny", label: "CNY" },
                  { value: "php", label: "PHP" },
                ]}
              />
            </Form.Item>
            <Form.Item label="Amount" name={"amount"}>
              <Input
                // style={{
                //   width: "80%",
                // }}
                autoFocus
              />
            </Form.Item>
            <Form.Item label="Payment Channel">
              <Segmented
                style={{
                  margin: 16,
                }}
                options={[
                  {
                    label: (
                      <img
                        style={{
                          maxWidth: 200,
                          width: "100%",
                          // margin: 16,
                        }}
                        src="https://paremit.com/wp-content/uploads/2024/08/PAREMIT_Logo_S_White.png"
                      />
                    ),
                    value: "paremit",
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              style={{
                marginBottom: 0,
              }}
            >
              <Button block type="primary" htmlType="submit" loading={loading}>
                Pay
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      {Object.keys(payment).length >= 2 &&
        Object.keys(received).length === 0 && (
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Card title="Scan to Pay">
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Image
                    style={{
                      width: "100%",
                    }}
                    src={payment.qrcode_image}
                  />
                </Col>
                <Col span={12}>
                  <Typography.Title
                    style={{
                      marginTop: 0,
                    }}
                    level={3}
                  >
                    Payment Instruction
                  </Typography.Title>
                  <Typography.Paragraph>
                    <ol>
                      <li>Open PAREMIT</li>
                      <li>Perform eKYC if needed</li>
                      <li>utilise the QRCode scanner to scan the above.</li>
                    </ol>
                    <Button
                      block
                      type="primary"
                      onClick={() => {
                        window.location.href =
                          "paremit://?key=https://paremit.com/payout/request?code=pFg654pyUQpcuXy7q5gi7m8nFRbQru5zGz54";
                      }}
                    >
                      Open PAREMIT
                    </Button>
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
      {Object.keys(payment).length >= 2 &&
        Object.keys(received).length > 0 &&
        true && (
          <Col span={12} xs={24} sm={24} md={12} lg={12}>
            <Card title="Scan to Pay">
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Typography.Title
                    style={{
                      marginTop: 0,
                      textAlign: "center",
                    }}
                    level={3}
                  >
                    Payment Received
                  </Typography.Title>
                  <Typography.Paragraph
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Result
                      status="success"
                      title="Payment Received "
                      subTitle={
                        <Statistic
                          value={received.amount}
                          suffix={received.currency}
                        />
                      }
                    />
                  </Typography.Paragraph>
                </Col>
              </Row>
            </Card>
          </Col>
        )}
    </Row>
  );
}

export default Contact;
