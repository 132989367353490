import TradingViewAdvanceChart from "../components/TradingViewAdvanceChart";
import TradingViewCustom from "../components/TradingViewCustom";
import { Col, Row } from "../libraries/Endeavour";

function Crypto() {
  return (
    <Row>
      <Col span={24}>
        <TradingViewCustom />
      </Col>
      <Col span={24}>
        <TradingViewAdvanceChart />
      </Col>
    </Row>
  );
}

export default Crypto;
