import QRCodeGenerator from "../components/QRCodeGenerator";
import { Col, Row } from "../libraries/Endeavour";

function QRCode() {
  return (
    <Row>
      <Col span={12}>
        <QRCodeGenerator />
      </Col>
    </Row>
  );
}

export default QRCode;
