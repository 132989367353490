import { useQuery } from "@tanstack/react-query";
import { Card, Skeleton, Space, Typography } from "antd";
import { ISO8601 } from "../libraries/Endeavour";

// https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=tc

function HKWeatherCard() {
  const query = useQuery({
    queryKey: ["data.weather.gov.hk", "rhrread"],
    queryFn: () => {
      return fetch(
        `https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=rhrread&lang=tc`
      )
        .then((res) => res.json())
        .catch(() => {
          return null;
        });
    },
    refetchInterval: 300000, // 5 MIN
  });

  const forecast = useQuery({
    queryKey: ["data.weather.gov.hk", "forecast"],
    queryFn: () => {
      return fetch(
        `https://data.weather.gov.hk/weatherAPI/opendata/weather.php?dataType=fnd&lang=tc`
      )
        .then((res) => res.json())
        .catch(() => {
          return null;
        });
    },
    refetchInterval: 300000, // 5 MIN
  });

  if (query.isFetching || forecast.isFetching) {
    return <Skeleton active />;
  }

  return (
    <Card title="Current Weather" extra={ISO8601(query.data.updateTime)}>
      <Typography>
        <Space>
          {query.data.icon.map((icon, i) => {
            return (
              <img
                alt={icon}
                style={{
                  maxHeight: 48,
                }}
                src={`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${icon}.png`}
              />
            );
          })}
          {forecast.data?.generalSituation && (
            <Typography.Paragraph copyable>
              {forecast.data.generalSituation}
            </Typography.Paragraph>
          )}
        </Space>
        {query.data?.warningMessage && (
          <Typography.Paragraph
            copyable
            style={{
              fontWeight: "bold",
            }}
          >
            {query.data.warningMessage}
          </Typography.Paragraph>
        )}
        {query.data?.tcmessage &&
          query.data.tcmessage.map((message) => {
            return (
              <Typography.Paragraph copyable>{message}</Typography.Paragraph>
            );
          })}
        {query.data?.humidity &&
          query.data.humidity.data.map((data) => {
            return (
              <Typography.Paragraph copyable>
                {data.place}目前相對濕度為 {data.value}%
              </Typography.Paragraph>
            );
          })}
        {query.data?.uvindex && (
          <Typography.Paragraph copyable>
            {`${query.data?.uvindex.data[0].place} 紫外線指數 ${query.data?.uvindex.data[0].value} (${query.data?.uvindex.data[0].desc})`}
          </Typography.Paragraph>
        )}
        {query.data?.specialWxTips && (
          <Typography.Paragraph copyable>
            {query.data.specialWxTips}
          </Typography.Paragraph>
        )}
      </Typography>
      <Card title="未來數天天氣預報" type="inner">
        {forecast.data.weatherForecast.map((data) => {
          return (
            <Card.Grid>
              <Typography>
                <Typography.Paragraph>
                  <Space>
                    <img
                      alt={data.ForecastIcon}
                      style={{
                        maxHeight: 24,
                      }}
                      src={`https://www.hko.gov.hk/images/HKOWxIconOutline/pic${data.ForecastIcon}.png`}
                    />
                    {data.week} {data.forecastDate}
                  </Space>
                  <div>
                    {data.forecastMintemp.value}&deg;C ~{" "}
                    {data.forecastMaxtemp.value}&deg;C
                  </div>
                </Typography.Paragraph>
                <Typography.Paragraph
                  style={{
                    marginBottom: 0,
                  }}
                >
                  {data.forecastWeather}
                  {data.forecastWind}
                </Typography.Paragraph>
              </Typography>
            </Card.Grid>
          );
        })}
      </Card>
    </Card>
  );
}

export default HKWeatherCard;
