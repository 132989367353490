import { Layout as ALayout, Menu } from "antd";
import { Link, Outlet, useLocation } from "react-router-dom";
import TradingViewTicker from "./components/TradingViewTicker";

const items = [
  {
    key: "/",
    label: <Link to="/">Home</Link>,
    children: [
      {
        key: "/dashboard",
        label: <Link to="/dashboard">Dashboard</Link>,
      },
    ],
  },
  // { key: 2, label: <Link to="/about-us">About</Link> },
  { key: "/data", label: <Link to="/data">Open Data</Link> },
  {
    key: "/tools",
    label: "Tools",
    children: [
      { key: "/tools/random", label: <Link to="/tools/random">Random</Link> },
      { key: "/tools/qrcode", label: <Link to="/tools/qrcode">QRCode</Link> },
      { key: "/tools/base64", label: <Link to="/tools/base64">Base64</Link> },
      { key: "/tools/hash", label: <Link to="/tools/hash">Hash</Link> },
      {
        key: "/tools/telegram",
        label: <Link to="/tools/telegram">Telegram</Link>,
      },
    ],
  },
  { key: "/network", label: <Link to="/network">Network</Link> },
  { key: "/crypto", label: <Link to="/crypto">Crypto</Link> },
  // { key: 5, label: <Link to="/geo">Geolocation</Link> },
  { key: "/contact-us", label: <Link to="/contact-us">Contact</Link> },
];

const PageContainer = () => {
  const { hash, pathname, search } = useLocation();
  console.log("h", hash, "p", pathname, "s", search);
  return (
    <ALayout>
      <TradingViewTicker />
      <ALayout.Header
        style={{
          padding: "0 16px",
        }}
      >
        {/* <img
      src={logo}
      style={{
        maxHeight: 56,
      }}
    /> */}
        <Menu
          theme="dark"
          mode="horizontal"
          // defaultSelectedKeys={["2"]}
          items={items}
          style={{
            flex: 1,
            minWidth: 0,
          }}
        />
      </ALayout.Header>
      <ALayout>
        {/* <ALayout.Sider
          style={{
            minHeight: "100vh",
            width: 160,
          }}
        >
          <Menu
            items={items}
            theme="dark"
            mode="inline"
            defaultOpenKeys={[pathname]}
            defaultSelectedKeys={[pathname]}
          />
        </ALayout.Sider> */}
        <ALayout.Content
          style={{
            padding: 16,
          }}
        >
          <Outlet />
        </ALayout.Content>
        {/* <ALayout.Footer>Powered by YIPSINT</ALayout.Footer> */}
      </ALayout>
    </ALayout>
  );
};

export default PageContainer;
